import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"
import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
    const pathsToRedirect = ["/recruit2/", "/recruit3/"];
    if (pathsToRedirect.includes(location.pathname)) {
      navigate("/404", { replace: true });
    }
  };